<template>
  <section>
    <div class="bg-white w-full p-4">
      <div class="py-2 border-b w-full flex gap-2 items-center">
        <i class="pi pi-cog text-blue-800 text-lg"></i>
        <p class="text-lg font-bold text-blue-800">Configuración</p>
      </div>
      <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
        <button @click="$router.push({ name: 'pharmasan.recursos-humanos.configuracion.cargos' })" class="w-full rounded-lg border p-4 hover:shadow-md">
          <div class="w-full flex justify-center">
            <img class="w-32" src="../../../../../../../../public/images/SVG/icono_cargos_rh.svg" alt="">
          </div>
          <div class="w-full flex justify-center">
            <p class="font-bold">Cargos</p>
          </div>
          <div class="w-full flex justify-center">
            <p class="">Acceso a la creación y edición de cargos</p>
          </div>
        </button>
        <button @click="$router.push({ name: 'pharmasan.recursos-humanos.configuracion.areas' })" class="w-full rounded-lg border p-4 hover:shadow-md">
          <div class="w-full flex justify-center">
            <img class="w-32" src="../../../../../../../../public/images/SVG/icono_cargos_rh.svg" alt="">
          </div>
          <div class="w-full flex justify-center">
            <p class="font-bold">Areas</p>
          </div>
          <div class="w-full flex justify-center">
            <p class="">Acceso a la creación y edición de areas</p>
          </div>
        </button>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: 'homeConfiguracionRH',
    setup () {
      return {}
    }
  }
</script>
